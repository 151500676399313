.active-squeezer {
  color: forestgreen;
}

.warning-squeezer {
  color: darkorange;
}

.error-squeezer, .error-squeezer .form-group .form-control {
  color: red;
}

.active-squeezer .form-group .form-control {
  color: forestgreen;
  font-size: large;
}

.warning-squeezer .form-group .form-control {
  color: darkorange;
  font-size: large;
}

.error-squeezer .form-group .form-control {
  color: red;
  font-size: large;
}

.unregistered-squeezer .form-group .form-control {
  font-size: large;
}